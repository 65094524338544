@import '@styles/sass/variables';
@import '@styles/sass/mixins/rem';

.productOverview {
  $self: &;
  &__section {
    position: relative;
  }
  &__cardTitle {
    span {
      font-weight: bold;
      @media only screen and (max-width: 767px) {
        font-weight: normal;
      }

      div {
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: rem(16) !important;
          @media only screen and (max-width: 767px) {
            font-size: rem(14) !important;
          }
          line-height: rem(20) !important;
          width: 100%;
        }
      }
    }
  }
}
